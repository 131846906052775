<ng-template #cardContent>
  <div class="card omgui-card p-3 d-flex flex-column flex-sm-row justify-content-sm-start align-items-center h-100">
    <div *ngIf="icon" class="mr-sm-3 mb-1 mb-sm-0">
      <ng-container *ngTemplateOutlet="icon.templateRef"></ng-container>
    </div>

    <h6 class="m-0 text-center text-sm-left"> {{ label }} </h6>
  </div>
</ng-template>

<ng-container *ngIf="link; then linkView; else staticView"></ng-container>

<ng-template #linkView>
  <a [routerLink]="link" [queryParams]="queryParams" class="text-decoration-none omgui-card-link-wrapper">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </a>
</ng-template>

<ng-template #staticView>
  <ng-container *ngTemplateOutlet="cardContent"></ng-container>
</ng-template>
