import { Injectable } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ConfigService } from '@app/core/config.service';

const PUSHER_SUBSCRIPTION_SUCCESS_EVENT = 'pusher:subscription_succeeded';

@Injectable({
  providedIn: 'root',
})
export class RealtimeCommunicationService {
  private readonly pusher: Pusher.Pusher;

  constructor(configService: ConfigService) {
    this.pusher = new Pusher(configService.json.pusherKey, { encrypted: true });
  }

  /**
   * @deprecated use getResponse
   */
  subscribe(channelName: string): Channel {
    return this.pusher.subscribe(channelName);
  }

  getResponse<T>(channelName: string, eventName: string): Observable<T> {
    const response$ = new Subject<T>();
    const channel: Channel = this.pusher.subscribe(channelName);
    channel.bind(eventName, (response: T) => {
      response$.next(response);
      channel.unbind(eventName);
    });

    return response$.asObservable().pipe(take(1));
  }

  getResponseAndSubscription<T>(channelName: string, eventName: string): [Observable<T>, Observable<void>] {
    const response$ = new Subject<T>();
    const subscribed$ = new ReplaySubject<void>();
    const channel = this.pusher.subscribe(channelName);

    if (channel.subscribed) {
      subscribed$.next();
    }

    channel.bind(eventName, (response: T) => {
      response$.next(response);
      channel.unbind(eventName);
    });

    channel.bind(PUSHER_SUBSCRIPTION_SUCCESS_EVENT, () => {
      subscribed$.next();
      channel.unbind(PUSHER_SUBSCRIPTION_SUCCESS_EVENT);
    });

    return [response$.asObservable().pipe(take(1)), subscribed$.asObservable().pipe(take(1))];
  }
}
