import { Injectable } from '@angular/core';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { Experiments, PatientExperimentService } from '@app/shared/patient-experiment.service';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseRoutingService {
  constructor(
    private patientExperimentService: PatientExperimentService,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  determineNextStep$(): Observable<StepName> {
    return combineLatest([
      this.featureFlagSelectors.getFeatureFlag(FeatureFlags.POST_REGISTRATION_BOOKING, false),
      this.featureFlagSelectors.getFeatureFlag(FeatureFlags.PROACTIVE_CARE_ENABLE_CONCIERGE, false),
      this.patientExperimentService.isVariantEnabled$(
        Experiments.APPOINTMENT_BOOKING_EXPERIMENT_NAME,
        Experiments.APPOINTMENT_BOOKING_EXPERIMENT_VARIANT_NAME,
      ),
    ]).pipe(
      map(([bookingActive, conciergeActive, appointmentBookingActive]) => {
        if (bookingActive) {
          return appointmentBookingActive ? StepName.appointmentBookingMultipleSlots : StepName.appointmentBooking;
        } else if (conciergeActive) {
          return StepName.concierge;
        }
      }),
      catchError(() => observableOf(undefined)),
    );
  }
}
