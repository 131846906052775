<div class="container mt-lg-10 mt-2">
  <form class="col-12 col-lg-10 offset-lg-1 px-lg-5" [formGroup]="form" (ngSubmit)="onSubmit()">
    <h1 class="text-center">Tell us more about yourself</h1>
    <p class="text-center mb-5 col-lg-10 offset-lg-1 px-0">
      We need your date of birth and sex for insurance purposes. If you have a different gender identity, please let us
      know.
    </p>
    <div class="col-lg-8 offset-lg-2 px-0">
      <div class="mb-3">
        <om-label-wrapper label="Date of birth" class="">
          <om-date-input #dateOfBirth formControlName="dob" class=""></om-date-input>
          <small *ngIf="showMinorError" class="d-block text-danger mt-2">
            You must be {{ minimumAge }} years old to complete registration. A parent or guardian can register a child
            using our <a [routerLink]="['/registration/pediatric']">Kids Registration</a>
          </small>
        </om-label-wrapper>
      </div>

      <div>
        <om-label-wrapper class="" errorMessage="You must choose an option." label="Sex" [hasError]="showSexError()">
          <om-toggle #sex [options]="sexOptions" formControlName="sex" classes="col-6 p-0 d-flex mb-1"></om-toggle>
        </om-label-wrapper>
        <small class="d-block text-black-50 mb-2">Select the option on file with your insurance</small>
        <a href *ngIf="!showGenderDetails" (click)="$event.preventDefault(); showGenderDetails = true" class="">
          Add gender information (optional)
        </a>
        <om-label-wrapper class="col-12 p-0 d-block" *ngIf="showGenderDetails" label="Gender Information (optional)">
          <textarea #genderDetails formControlName="genderDetails"></textarea>
        </om-label-wrapper>
      </div>
      <om-submit-button class="col-12" label="Next" [submitting]="submitting"></om-submit-button>
    </div>
  </form>

  <ng-template #dependentNotFound>
    <om-modal-template
      heading="Incorrect information."
      subheader="The information entered doesn’t match the records provided by the organization benefits team. Try entering your information again or contact your benefits team to confirm your information."
      primaryCallToActionLabel="Edit your information"
      (close)="modalService.dismissAll()"
      (primaryCallToAction)="modalService.dismissAll()"
    >
      <om-svg-exclamation></om-svg-exclamation>
    </om-modal-template>
  </ng-template>

  <ng-template #dependentRegistered>
    <om-modal-template
      heading="An account already exists."
      subheader="The information you provided is already associated with an account. Log in or contact our membership advisors for help."
      primaryCallToActionLabel="Log In"
      primaryClasses="modal-footer pt-0 px-md-5 pb-4 border-0 justify-content-center"
      (close)="modalService.dismissAll()"
      (primaryCallToAction)="logIn()"
    >
      <om-svg-exclamation></om-svg-exclamation>
      <small custom class="d-block text-center pb-4">
        Lost access to your account? <a href="mailto:hello@onemedical.com">hello@onemedical.com</a>
      </small>
    </om-modal-template>
  </ng-template>
</div>
