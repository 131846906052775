import { createAction, props } from '@ngrx/store';

import { RegisterSteps } from '@app/registration/consumer/consumer-registration.constants';
import { RegisterFlows, RegisterDiscountTypes } from '@app/registration/consumer/consumer-registration.constants';
import { IPaymentMethod } from '@app/registration/consumer/payment-method.reducer';
import { Address, BasicAddress } from '@app/shared/address';
import { AddressSuggestion } from '@app/shared/address-suggestions.service';
import { Coupon } from '@app/shared/coupon';
import { EmailVerificationStatus } from '@app/shared/email-verification-status';
import { ServiceArea } from '@app/shared/service-area';
import { User } from '@app/shared/user';

export const registerInit = createAction('[Register] Initialize');
export const setAllServiceAreas = createAction(
  '[Register] Set All ServiceAreas',
  props<{ serviceAreas: ServiceArea[] }>(),
);

export const registerReset = createAction('[Register] Reset');
export const registerRestart = createAction('[Register] Restart', props<{ user: User }>());
export const registerContinue = createAction('[Register] Continue');

export const registerGoBackOneStep = createAction('[Register] Go Back One Step');
export const registerGoToStep = createAction('[Register] Go to provided step', props<{ step: RegisterSteps }>());
export const resetCodeValidationAndAmf = createAction('[Register] Reset Code Validation');

// Basic Info Actions
export const registerBasicInfo = createAction('[Register] Register Basic Info');
export const registerBasicInfoSuccess = createAction('[Register] Success', props<{ user: any }>());
export const registerBasicInfoFailure = createAction('[Register] Failure!!', props<{ error: string }>());
export const setIsEmailTaken = createAction('[Register] Set Email Is Taken', props<{ isTaken: boolean }>());
export const checkEmailSuccess = createAction('[Register] Check Email Success');

// Email Verification Actions
export const verifyEmail = createAction('[Register] Verify Email', props<{ code: string }>());
export const verificationSuccessful = createAction('[Register] Email Verification Successful');
export const skipVerification = createAction('[Register] Skip Email Verification');
export const sendVerificationCode = createAction(
  '[Register] Send Email Verification Code',
  props<{ resend: boolean }>(),
);
export const emailVerificationFailure = createAction(
  '[Register] Email Verification Failed',
  props<{ status: EmailVerificationStatus }>(),
);
export const emailTechSupport = createAction('[Register] Email Tech Support');
export const setEmailVerificationErrorMessage = createAction(
  '[Register] Set Email Verification Error Message',
  props<{ errorMessage: string }>(),
);

export const accountLocked = createAction('[Register] Account Locked');

// Location Actions
export const setAutoCompleteAddress = createAction('[Register] Set address', props<{ address: Address }>());
export const registerLocation = createAction('[Register] Register Location');
export const registerLocationSuccess = createAction('[Register] Register Location Success', props<{ user: any }>());
export const registerLocationFailure = createAction(
  '[Register] Register Location Failure!!',
  props<{ error: string }>(),
);
export const foundManualAddressSuggestions = createAction(
  '[Register] Found Manual Address Suggestions',
  props<{ enteredAddress: BasicAddress; suggestions: AddressSuggestion[] }>(),
);
export const suggestedAddressSelected = createAction(
  '[Register] Suggested Address Selected',
  props<{ placeId: string }>(),
);

// TOS Actions
export const registerTermsOfServiceComplete = createAction('[Register] Register Terms of Service Complete');

// Personal Info Actions
export const registerPersonalInfo = createAction('[Register] Register Personal Info');
export const registerPersonalInfoSuccess = createAction(
  '[Register] Register Personal Info Success',
  props<{ user: any }>(),
);
export const registerPersonalInfoFailure = createAction(
  '[Register] Register Personal Info Failure!!',
  props<{ error: string }>(),
);
export const sendAppDownloadSMS = createAction('[Register] Send App Download SMS');
export const sendAppDownloadSMSSuccess = createAction(
  '[Register] Send App Download SMS Success',
  props<{ user: any }>(),
);
export const sendAppDownloadSMSFailure = createAction(
  '[Register] Send App Download SMS Failure',
  props<{ error: string }>(),
);
export const registerUserUnder18Detected = createAction('[Register] DOB under 18 detected');

// Payment / Billing Actions
export const registerSkipPaymentInfo = createAction('[Register] Register Skip Payment Info');

export const registerPaymentInfo = createAction(
  '[Register] Register Payment Info',
  props<{
    paymentMethodId?: string;
    token?: string;
    discountCode?: string;
    giftCode?: string;
    paymentIntentId?: string;
  }>(),
);
export const registerCreditCardStatus = createAction(
  '[Register] Register Credit Card Status',
  props<{ status: boolean; discountCode: string; message: string }>(),
);
export const registerDiscountCodePresent = createAction(
  '[Register] Register Discount Code Entered',
  props<{ discountCode: string; isAutoApplied: boolean }>(),
);
export const registerDiscountCodeCleared = createAction('[Register] Register Discount Code Cleared');

export const registerDiscountCodeValid = createAction(
  '[Register] Register Discount Code Valid',
  props<{
    coupon: Coupon;
    discountType: RegisterDiscountTypes;
    discountCode: string;
    trialDays: number;
    trialAutoRenew: boolean;
    trialSkipCC: boolean;
    flow?: RegisterFlows;
    isAutoApplied: boolean;
  }>(),
);

export const registerDiscountCodeInvalid = createAction(
  '[Register] Register Discount Code Invalid',
  props<{ couponError: string; discountCode: string }>(),
);

export const registerGiftCodeInvalid = createAction(
  '[Register] Register Gift Code Invalid',
  props<{ errorMessage: string; giftCode: string }>(),
);

export const registerProcessPaymentIntent = createAction(
  '[Register] Process Payment Intent',
  props<{ paymentIntentId: string }>(),
);

export const registerPaymentAttempt = createAction('[Register] Register Payment Attempt');

export const registerPaymentInfoSuccess = createAction(
  '[Register] Register Payment Info Success',
  props<{ user: any }>(),
);

export const registerPaymentInfoFailure = createAction(
  '[Register] Register Payment Info Failure!!',
  props<{ error: any }>(),
);

export const clearPaymentInfoFailure = createAction('[Register] Register Clear Payment Error');
export const createOrFindStripePaymentIntent = createAction('[Register] Create Stripe Payment Intent');
export const stripePaymentIntentCreated = createAction(
  '[Register] Stripe Payment Intent created',
  props<{ clientSecret: string }>(),
);
export const continueWithAfterpay = createAction('[Register] Continue with Afterpay');
export const continueWithAfterpayFailure = createAction(
  '[Register] Continue with Afterpay Failure',
  props<{ error: any }>(),
);

// Post registration actions
export const setConsumerPromoAvailable = createAction(
  '[Register] Set Consumer Promo Availability',
  props<{ isConsumerPromoAvailable: boolean }>(),
);

export const setApplePayAvailable = createAction(
  '[Register] Set ApplePay Availability',
  props<{ isApplePayAvailable: boolean }>(),
);

export const setPaymentMethod = createAction(
  '[Register] Set Payment Method',
  props<{ paymentMethod: IPaymentMethod['paymentMethod'] }>(),
);

// Overall Flow Actions
export const registerNewStepLoaded = createAction('[Register] Register New Step Loaded');
export const registerRegistrationSuccessful = createAction('[Register] Register Consumer Reg Successful');
export const registerPaidFlowActivated = createAction('[Register] Register Paid Flow Activated');
export const registerTrialCodeFound = createAction(
  '[Register] Register Free Trial Code Found',
  props<{ trialCode: string; isAutoApplied: boolean }>(),
);
export const registerGiftCodeFound = createAction(
  '[Register] Register Gift Code Found',
  props<{ giftCode: string; isAutoApplied: boolean }>(),
);
export const registerFreeTrialFlowActivated = createAction(
  '[Register] Register Free Trial Activated',
  props<{
    trialCode: string;
    trialDays: number;
    trialAutoRenew: boolean;
    isAutoApplied: boolean;
    trialSkipCC: boolean;
  }>(),
);
export const registerGiftFlowActivated = createAction(
  '[Register] Register Gift Flow Activated',
  props<{ giftCode: string; isAutoApplied: boolean }>(),
);
export const registerDiscountFlowActivated = createAction(
  '[Register] Register Discount Code Flow Activated',
  props<{ discountCode: string; isAutoApplied: boolean }>(),
);
export const registerReferralFlowActivated = createAction(
  '[Register] Register Referral Code Activated',
  props<{ discountCode: string; isAutoApplied: boolean }>(),
);
export const registerFamilyFlowActivated = createAction('[Register] Register Family Flow Activated');

export const registerFamilyFlowWithDiscountActivated = createAction(
  '[Register] Register Family Flow with Discount Code Activated',
  props<{ discountCode: string; isAutoApplied: boolean }>(),
);

export const canValidateDiscountCode = createAction(
  '[Register] Register Can Validate Discount (User and Service Area is Present)',
  props<{ patientId: number; serviceAreaCode: string }>(),
);
export const registerIsLoadAMF = createAction(
  '[Register] Register Can Load the AMF (User and Service Area is Present)',
  props<{ patientId: number; serviceAreaCode: string }>(),
);

export const registerAmfLoaded = createAction('[Register] Register Loaded the AMF', props<{ amf: number }>());

export const registerShowDiscountCodeClicked = createAction('[Register] Show Discount Code Link Clicked');
export const registerPaymentInfoRendered = createAction('[Register] Register Payment Info Rendered');
export const registerNoop = createAction('[Register] NoOp');
export const registerUpdateFlow = createAction('[Register] Register Update Flow', props<{ flow: RegisterFlows }>());
export const registerConfirmationSurveyClicked = createAction('[Register] Confirmation Survey Clicked');
export const registerConfirmationNewAppointmentClicked = createAction(
  '[Register] Confirmation New Appointment Clicked',
);
export const continueToConsumerOnboardingClicked = createAction('[Register] Continue to Consumer Onboarding Clicked');
