export const FeatureFlags = {
  APPOINTMENT_BOOKING_ENHANCED_STEPS: 'appointment-booking-enhanced-steps',
  APPOINTMENT_BOOKING_STEP_BASED_NAVIGATION: 'appointment-booking-step-based-navigation',
  APPOINTMENT_SEARCH_NEXT_REMOTE_VISIT_MODULE: 'next-remote-visit-module',
  CONSUMER_PEDIATRIC_REGISTRATION_FREE_TRIAL: 'consumer-pediatric-registration-free-trial',
  CONSUMER_REGISTRATION_APPLE_PAY_WEB: 'consumer-registration-apple-pay-web',
  CONSUMER_REGISTRATION_DPH_BANNER_MESSAGE: 'consumer-registration-dph-banner-message',
  CONSUMER_REGISTRATION_INSTALLMENT_PAYMENTS: 'consumer-registration-installment-payments',
  CONSUMER_REGISTRATION_STRIPE_PAYMENT_INTENTS: 'consumer-registration-stripe-payment-intents',
  CONSUMER_REGISTRATION_UNIVERSAL_PROMOTIONAL_PRICING: 'consumer-registration-universal-promotional-pricing',
  SECURITY_MFA: 'security-mfa',
  MEDICAL_RECORDS_DOWNLOAD: 'patient-medical-records-download-request',
  MEMBERSHIP_NEW_STRIPE_WORKFLOW_CONSUMER_REGISTRATION: 'membership-new-stripe-workflow-consumer-registration',
  MEMBERSHIP_NEW_STRIPE_WORKFLOW_GIFT_MEMBERSHIP: 'membership-new-stripe-workflow-gift-membership',
  PROACTIVE_CARE_ENABLE_PCP_SELECTION_ONBOARDING_CAROUSEL_CARD:
    'proactive-care-enable-pcp-selection-onboarding-carousel-card',
  PC_NEW_MED_RELEASE_FORM: 'pc-new-med-release-form',
  LOCATION_ATTESTATION: 'location-attestation',
  HOME_SCREEN_REDESIGN: 'home-screen-redesign',
  REFERRAL_PDF: 'referral-pdf',
  POST_REGISTRATION_BOOKING: 'post-registration-booking',
  BHX_MEMBER_PROGRAM_EXPERIENCE: 'member-program-experience',
  PROACTIVE_CARE_ENABLE_CONCIERGE: 'proactive-care-enable-concierge',
  PROACTIVE_CARE_ENABLE_CONSUMER_MULTIPLE_SLOTS_BOOKING: 'proactive-care-enable-multiple-slots-booking',
};

export enum HomeRedesignVariation {
  OFF = 'Off',
}
