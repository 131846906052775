import { Injectable } from '@angular/core';
import { SafeHtml, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import lodashGet from 'lodash-es/get';
import moment from 'moment-timezone';
import { BehaviorSubject, interval, Observable, of as observableOf, ReplaySubject, zip } from 'rxjs';
import { concatMap, debounce, filter, map, switchMap, take } from 'rxjs/operators';

import { ChannelRoutingGraphQL, Meta } from '@app/appointment/channel-routing-graphql.service';
import { ApiService } from '@app/core/api.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { MarkdownService } from '@app/core/markdown.service';
import { UserService } from '@app/core/user.service';
import { ContentBlockService } from '@app/shared';
import { Features } from '@app/shared/active-feature-flag-collection';
import { FeatureFlagService } from '@app/shared/feature-flag.service';
import { ServiceArea } from '@app/shared/service-area';

import { AppointmentAnalyticsService } from '../appointment-analytics.service';
import { AppointmentType } from '../appointment-type';
import { AppointmentService } from '../appointment.service';
import { FromAppointment } from '../from-appointment';
import { AppointmentBookingState } from './appointment-booking-state';
import {
  APPOINTMENT_BOOKING_VIRTUAL_CARE_ROUTE,
  AppointmentBookingStep,
  AppointmentBookingSteps,
} from './appointment-booking-steps';

interface FetchRecommendedAppointmentTypeData {
  appointmentType: AppointmentType;
  channel?: string;
  meta?: Meta;
  topic?: string;
}

interface AppointmentForRescheduling {
  id: string;
  startAt: string;
  office: {
    name: string;
  };
  provider: {
    displayName: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentBookingStateService {
  currentStep: AppointmentBookingStep;
  contentForSelectAppointmentType$: ReplaySubject<string | null>;
  private _percentComplete$ = new BehaviorSubject<number>(0);
  percentComplete$ = this._percentComplete$.asObservable();

  private appointmentBookingState: AppointmentBookingState;
  private appointmentBookingSteps: AppointmentBookingSteps;
  private _currentSelectedServiceArea: ServiceArea;

  constructor(
    private apiService: ApiService,
    private appointmentAnalyticsService: AppointmentAnalyticsService,
    private appointmentService: AppointmentService,
    private channelRoutingGraphQL: ChannelRoutingGraphQL,
    private featureFlagSelectors: FeatureFlagSelectors,
    private featureFlagService: FeatureFlagService,
    private markdownService: MarkdownService,
    private router: Router,
    private title: Title,
    private userService: UserService,
    private contentBlockService: ContentBlockService,
  ) {
    this.appointmentBookingSteps = new AppointmentBookingSteps();
    this.title.setTitle('Appointment Booking');
    this.setAppointmentBookingState(new AppointmentBookingState());
    this.currentStep = this.appointmentBookingSteps.initialStep();
    this.updateBookingStateOnUserServiceAreaChange();
  }

  setAppointmentBookingState(appointmentBookingState: AppointmentBookingState) {
    this.appointmentBookingState = appointmentBookingState;

    this.featureFlagSelectors
      .getFeatureFlag(FeatureFlags.APPOINTMENT_BOOKING_ENHANCED_STEPS, false)
      .subscribe(flagActive => {
        this.appointmentBookingState.setEnhancedBookingEnabled(flagActive);
      });

    this.userService.user$.pipe(take(1)).subscribe(user => {
      if (!this.appointmentBookingState.serviceAreaSet()) {
        this.appointmentBookingState.setSelectedServiceArea(user.serviceArea);
      }
    });

    this.appointmentAnalyticsService.setBookingState(appointmentBookingState);
    this.appointmentBookingSteps.setAppointmentBookingState(appointmentBookingState);
    this.initContentForSelectAppointmentType();
  }

  getAppointmentBookingState() {
    return this.appointmentBookingState;
  }

  getAppointmentBookingSteps(): AppointmentBookingSteps {
    return this.appointmentBookingSteps;
  }

  get currentSelectedServiceArea() {
    return this._currentSelectedServiceArea;
  }

  set currentSelectedServiceArea(serviceArea: ServiceArea) {
    this._currentSelectedServiceArea = serviceArea;
  }

  setIsRecommendedRemoteAppointment(isRecommendedRemoteAppointment: boolean) {
    this.appointmentBookingState.setIsRecommendedRemoteAppointment(isRecommendedRemoteAppointment);
  }

  initContentForSelectAppointmentType() {
    const enabled$ = this.featureFlagService.featureActive$(Features.APPOINTMENT_TYPE_HEADER_CONTENT_WEB).pipe();
    const content$ = this.appointmentBookingState.selectedServiceArea$.pipe(
      debounce(() => interval(200)),
      concatMap(serviceArea => zip(observableOf(serviceArea), enabled$)),
      concatMap(([serviceArea, enabled]) => {
        if (!enabled) {
          return observableOf(null);
        }

        const serviceAreaId = serviceArea ? serviceArea.id : null;
        return this.contentBlockService
          .getContentBlock('appointment-type', 'default', 'appointment-type-select-header-web', serviceAreaId)
          .pipe(map((resp: any) => (resp.html ? this.markdownService.convertWithHeadings(resp.html) : null)));
      }),
    );
    const contentSubject$: ReplaySubject<string | null> = new ReplaySubject(1);
    content$.subscribe(contentSubject$);
    this.contentForSelectAppointmentType$ = contentSubject$;
  }

  contentForSearchAppointmentType(): Observable<SafeHtml> {
    return this.appointmentBookingState.selectedServiceArea$.pipe(
      take(1),
      switchMap(serviceArea => {
        const serviceAreaId = serviceArea ? serviceArea.id : null;
        return this.contentBlockService
          .getContentBlock(
            'appointment-type',
            this.appointmentBookingState.appointmentType.id,
            'appointment-type-search-content',
            serviceAreaId,
          )
          .pipe(map((resp: any) => (resp.html ? this.markdownService.convertWithHeadings(resp.html) : null)));
      }),
    );
  }

  advance() {
    const nextStep = this.currentStep.next();

    if (nextStep.percentComplete === 100) {
      this._percentComplete$.next(nextStep.percentComplete);
      return this.appointmentService.saveBookingState(this.appointmentBookingState).subscribe(() => {
        this.loadStep(nextStep);
      });
    }
    this.loadStep(nextStep);
  }

  advanceAvailable(): boolean {
    return (
      Boolean(this.currentStep.next()) &&
      this.currentStep.next().isReadyToLoad() &&
      this.shouldHaveNextButton(this.currentStep.route())
    );
  }

  loadStep(step: AppointmentBookingStep, navigateToStep = true, source?: string): AppointmentBookingStep {
    if (!step.isReadyToLoad()) {
      this.loadStep(this.appointmentBookingSteps.initialStep());
      const state = this.appointmentBookingState;
      throw new Error(
        `AppointmentBookingState not ready to advance. Requirements - \
        ${step.isReadyToLoad} | BookingState \
        freeTextReason: ${state.freeTextReason}; \
        providerTypeSelected: ${state.providerTypeSelected()}; \
        serviceAreaSet: ${state.serviceAreaSet()}; \
        appointmentTypeSelected: ${state.appointmentTypeSelected()}`,
      );
    }
    this.currentStep = step;
    this._percentComplete$.next(this.currentStep.percentComplete);
    const route = step.route();

    if (!this.router.isActive(route, true) && navigateToStep) {
      if (source) {
        this.router.navigate([route], { queryParams: { source } });
      } else {
        this.router.navigate([route]);
      }
    }
    return step;
  }

  fetchRecommendedAppointmentType(): Observable<FetchRecommendedAppointmentTypeData> {
    if (this.appointmentBookingState.associatedTask && this.appointmentBookingState.appointmentType) {
      return observableOf({ appointmentType: this.appointmentBookingState.appointmentType });
    }
    const serviceArea = this.appointmentBookingState.getSelectedServiceArea();
    const apiParams = {
      inputText: this.appointmentBookingState.reason,
      serviceAreaId: serviceArea && serviceArea.id,
    };

    const request = zip(
      this.quickumlsRecommendedAppointmentType$(apiParams),
      this.channelRoutingRecommendedAppointmentType$(apiParams),
    ).pipe(
      take(1),
      map(([quickumlsAppointmentType, channelRoutingResp]) => {
        let fromChannelRouting: FetchRecommendedAppointmentTypeData;
        let fromQuickumls: FetchRecommendedAppointmentTypeData;
        const fromNoResponse = { appointmentType: null, channel: null, meta: null, topic: null };

        if (channelRoutingResp) {
          fromChannelRouting = {
            appointmentType: AppointmentType.fromGraphQL(channelRoutingResp.appointmentTypeData),
            channel: channelRoutingResp.channel,
            meta: channelRoutingResp.meta,
            topic: channelRoutingResp.topic,
          };
        }
        if (quickumlsAppointmentType) {
          fromQuickumls = {
            appointmentType: AppointmentType.fromApiV2(quickumlsAppointmentType),
          };
        }

        if (fromChannelRouting) {
          return fromChannelRouting;
        }
        if (quickumlsAppointmentType) {
          return fromQuickumls;
        }
        return fromNoResponse;
      }),
    );

    request.subscribe(({ appointmentType, channel, meta, topic }) => {
      this.appointmentBookingState.updateRecommendedAppointmentType(appointmentType, channel, topic);
      if (meta) {
        this.appointmentBookingState.routingMetadataUuid = meta.metadataUuid;
      }
    });
    return request;
  }

  setStateForRescheduling(appointment: AppointmentForRescheduling, displayTimezone: string) {
    const fromAppointment = new FromAppointment();
    fromAppointment.id = parseInt(appointment.id, 10);
    fromAppointment.officeName = appointment.office.name;
    fromAppointment.providerName = appointment.provider.displayName;
    fromAppointment.startTime = moment.tz(appointment.startAt, displayTimezone);
    this.getAppointmentBookingState().setFromAppointment(fromAppointment);
  }

  private channelRoutingRecommendedAppointmentType$(params: {
    inputText: string;
    serviceAreaId: number;
  }): Observable<{ appointmentTypeData: any; channel: string; meta: Meta; topic: string }> {
    const { inputText, serviceAreaId } = params;
    const request = this.channelRoutingGraphQL
      .fetch({ channel: 'inputText', inputText, serviceAreaId: '' + serviceAreaId }, { fetchPolicy: 'network-only' })
      .pipe(
        filter(res => !res.loading),
        map(resp => {
          const appointmentTypeData = lodashGet(resp, 'data.channelRouting.appointmentSearch.appointmentType') as any;
          // for now not checking for channel
          //    Later we may implement full control of this flow to channel routing,
          //    in which case we may want to check that channel === 'appointmentSearch'
          if (appointmentTypeData) {
            const channel = lodashGet(resp, 'data.channelRouting.channel') as string;
            const topic = lodashGet(resp, 'data.channelRouting.topic') as string;
            const meta = lodashGet(resp, 'data.channelRouting.meta') as Meta;
            return { appointmentTypeData, channel, meta, topic };
          }
        }),
      );

    return request;
  }

  private quickumlsRecommendedAppointmentType$(params: {
    inputText: string;
    serviceAreaId: number;
  }): Observable<AppointmentType> {
    const { inputText: reason, serviceAreaId: service_area_id } = params;
    const request = this.apiService
      .post('/api/v2/patient/quickumls/get_appointment_type_by_reason', { reason, service_area_id }, false)
      .pipe(map(resp => resp['appointment_type']));

    return request;
  }

  private updateBookingStateOnUserServiceAreaChange() {
    this.userService.user$.subscribe(user => {
      this.appointmentBookingState.setSelectedServiceArea(user.serviceArea);
    });
  }

  private shouldHaveNextButton(path: string) {
    const arrayOfExcludedPaths = [APPOINTMENT_BOOKING_VIRTUAL_CARE_ROUTE];
    return !arrayOfExcludedPaths.includes(path);
  }
}
