import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/core/auth.service';

import { AnalyticsService } from './core/analytics.service';
import { WindowService } from './core/window.service';

@Component({
  selector: 'om-app',
  templateUrl: './app.component.html',
  providers: [],
})
// Component controller
export class MyOneApp implements OnInit {
  constructor(
    private windowService: WindowService,
    private analyticsService: AnalyticsService,
    private authService: AuthService, // to ensure this is initialized top level
  ) {}

  ngOnInit() {
    this.windowService.appInstallChoiceStream.subscribe(choiceResult => {
      if (choiceResult.outcome === 'dismissed') {
        this.analyticsService.appInstallBannerDismissed();
      } else {
        this.analyticsService.appInstallBannerAccepted();
      }
    });
  }
}
