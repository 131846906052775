<ng-template #cardContent>
  <div class="position-relative h-100">
    <div
      class="card omgui-card d-flex p-3 p-sm-4 flex-row flex-sm-column flex-md-row align-items-center align-items-sm-start align-items-md-center justify-content-start h-100"
    >
      <div *ngIf="icon" class="mr-3 mr-md-4 mb-sm-2 mb-md-0 icon">
        <ng-container *ngTemplateOutlet="icon.templateRef"></ng-container>
      </div>

      <div>
        <h6 class="m-0">{{ label }}</h6>
        <div class="sublabel text-emphasis-medium">{{ sublabel }}</div>
      </div>
    </div>

    <div *ngIf="footer" class="position-absolute w-100 text-center mx-auto my-2 d-none d-sm-block text-emphasis-medium">
      <ng-container *ngTemplateOutlet="footer.templateRef"></ng-container>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="link; then linkView; else defaultView"></ng-container>

<ng-template #defaultView>
  <div role="button" (click)="onClick($event)" class="omgui-card-link-wrapper">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </div>
</ng-template>

<ng-template #linkView>
  <a [routerLink]="link" class="text-decoration-none omgui-card-link-wrapper">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </a>
</ng-template>
