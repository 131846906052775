import { RoutingGroupOption } from '@app/shared';

export enum OptionValue {
  chooseProvider = 'choose-provider',
  healthHistory = 'health-history',
  requestMeds = 'request-meds',
}

export const chooseYourProvider: RoutingGroupOption<OptionValue> = {
  header: 'Choose your provider',
  description: 'Select a nearby primary care provider who’s right for you',
  value: OptionValue.chooseProvider,
  iconUrl: 'assets/images/icons/registration/provider.svg',
};

export const healthHistory: RoutingGroupOption<OptionValue> = {
  header: 'Add your health history',
  description: 'Share your health history to personalize your experience',
  value: OptionValue.healthHistory,
  iconUrl: 'assets/images/icons/registration/chart.svg',
};

export const requestMedication: RoutingGroupOption<OptionValue> = {
  header: 'Request a new medication',
  description: 'Refill or request a prescription in a few taps',
  value: OptionValue.requestMeds,
  iconUrl: 'assets/images/icons/registration/medication.svg',
  badgeText: 'mobile app',
};

export const conciergeDefaultOptions: RoutingGroupOption<OptionValue>[] = [
  healthHistory,
  chooseYourProvider,
  requestMedication,
];
