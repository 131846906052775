import { AppointmentBookingState } from './appointment-booking-state';

export const APPOINTMENT_BOOKING_START_ROUTE = '/appointments/reason';
export const APPOINTMENT_BOOKING_VIRTUAL_CARE_ROUTE = '/appointments/virtual-care';

export interface AppointmentBookingStep {
  route: () => string;
  isReadyToLoad: () => boolean;
  next: () => AppointmentBookingStep;
  previous: () => AppointmentBookingStep;
  percentComplete: number;
}

/**
 *  This Class serves a few purposes:
 *      1. enumerates the different possible booking steps
 *      2. Maps each step to a view route
 *      3. contains fallback logic for routing to the next step,
 *           - ChannelRouting service will be wired in upstream
 */
export class AppointmentBookingSteps {
  constructor() {}
  private state: AppointmentBookingState;

  ReasonForVisit: AppointmentBookingStep = {
    route: () => APPOINTMENT_BOOKING_START_ROUTE,
    isReadyToLoad: () => true,
    next: () => {
      if (this.state.requiredSurveyId) {
        return this.Survey;
      }
      return this.SelectType;
    },
    previous: () => undefined,
    percentComplete: 0,
  };

  SelectType: AppointmentBookingStep = {
    route: () => '/appointments/select-type',
    isReadyToLoad: () => this.state.freeTextReason && this.state.serviceAreaSet() && this.state.providerTypeSelected(),
    next: () => (!!this.state.requiredSurveyId && !this.state.surveyData ? this.Survey : this.Search),
    previous: () => this.ReasonForVisit,
    percentComplete: 57,
  };

  Survey: AppointmentBookingStep = {
    route: () => `/appointments/survey/${this.state.requiredSurveyId}`,
    isReadyToLoad: () => !!this.state.requiredSurveyId && this.state.freeTextReason && this.state.serviceAreaSet(),
    next: () => this.Search,
    previous: () => (this.state.getIsAppointmentRecommended() ? this.ReasonForVisit : this.SelectType),
    percentComplete: 75,
  };

  Search: AppointmentBookingStep = {
    route: () => '/appointments/search',
    isReadyToLoad: () => this.state.bookingStateComplete(),
    next: () => null,
    previous: () => null,
    percentComplete: 100,
  };

  setAppointmentBookingState(state: AppointmentBookingState) {
    this.state = state;
  }

  initialStep() {
    return this.ReasonForVisit;
  }
}
