import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { noop, Observable, of as observableOf, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppointmentAnalyticsService } from '@app/appointment/appointment-analytics.service';
import { AppointmentBookingStateService } from '@app/appointment/appointment-booking-state-service';
import { DATE_FORMAT, TIME_FORMAT } from '@app/appointment/appointment.utils';
import { LinksService } from '@app/core/links.service';

import { formatDate } from '../date-format.pipe';
import { AppointmentToRescheduleGraphQLService } from './appointment-to-reschedule-graphql.service';

interface Appointment {
  id: string;
  startAt: string;
  office?: {
    name: string;
  };
  provider: {
    displayName: string;
  };
}

@Component({
  selector: 'om-appointment-more-options',
  templateUrl: './appointment-more-options.component.html',
})
export class AppointmentMoreOptionsComponent {
  @Input() set appointment(appointment: Appointment) {
    this._appointment = appointment;
    this.detailsLink = this.links.appointmentConfirmation(appointment.id);
  }

  @Input() displayTimezone: string;
  @Input() disabled: boolean;
  @Input() name = 'More Options';

  @Output() cancelClicked = new EventEmitter<void>();
  @Output() cancelConfirmed = new EventEmitter<void>();
  @Output() runningLateConfirmed = new EventEmitter<void>();

  @ViewChild('confirmationModal') modal;

  modalTitle: string;
  modalBody: string;
  detailsLink: string;
  readonly cancelButtonLabel = 'Cancel';
  readonly rescheduleButtonLabel = 'Reschedule';
  readonly viewDetailsButtonLabel = 'View Details';
  readonly runningLateButtonLabel = "I'm running late";

  private _appointment: Appointment;
  private readonly lateModalTitle = 'Notify us that you are running late to this appointment?';
  private readonly cancelModalTitle = 'Cancel this appointment?';

  constructor(
    private router: Router,
    private analytics: AppointmentAnalyticsService,
    private bookingStateService: AppointmentBookingStateService,
    private modalService: NgbModal,
    private links: LinksService,
    private appointmentForReschedulingGraphQL: AppointmentToRescheduleGraphQLService,
  ) {}

  rescheduleAppointment() {
    this.analytics.trackRescheduleStarted(this._appointment.id);

    observableOf(this._appointment)
      .pipe(
        switchMap(({ id, office }) => {
          if (office) {
            return observableOf(office);
          } else {
            return this.appointmentForReschedulingGraphQL
              .fetch({ id })
              .pipe(map(response => response.data.appointment.office));
          }
        }),
      )
      .subscribe({
        next: office => {
          this.bookingStateService.setStateForRescheduling({ ...this._appointment, office }, this.displayTimezone);

          this.router.navigateByUrl('/appointments/reschedule-reason');
        },
      });
  }

  showCancelConfirmation() {
    this.cancelClicked.emit();
    this.showConfirmationModal(this.cancelModalTitle).subscribe({
      next: () => this.cancelConfirmed.emit(),
    });
  }

  showRunningLateConfirmation() {
    this.showConfirmationModal(this.lateModalTitle).subscribe({
      next: () => this.runningLateConfirmed.emit(),
    });
  }

  private showConfirmationModal(title: string): Observable<void> {
    const time = formatDate(this._appointment.startAt, `${DATE_FORMAT} ${TIME_FORMAT}`, this.displayTimezone);
    this.modalBody = `${time} with ${this._appointment.provider.displayName}`;
    this.modalTitle = title;

    const subject = new Subject<void>();
    this.modalService
      .open(this.modal)
      .result.then((confirmed: boolean) => {
        if (confirmed) {
          subject.next();
        }
      })
      .catch(noop)
      .finally(() => subject.complete());

    return subject.asObservable();
  }
}
