import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OmguiActionComponent, OmguiActionModule } from './omgui-action/omgui-action.component';
import { OmguiAppointmentProviderAvatarComponent } from './omgui-appointment-provider-avatar/omgui-appointment-provider-avatar.component';
import { OmguiCloseXModule } from './omgui-close-x/omgui-close-x.component';
import { OmguiDisplayCardModule } from './omgui-display-card/omgui-display-card.component';
import { OmguiExternalLinkIconModule } from './omgui-external-link-icon/omgui-external-link-icon.component';
import { OmguiFeatureCardComponent, OmguiFeatureCardModule } from './omgui-feature-card/omgui-feature-card.component';
import { OmguiProfileBubbleComponent } from './omgui-profile-bubble/omgui-profile-bubble.component';
import { OmguiProviderProfileBubbleComponent } from './omgui-profile-bubble/omgui-provider-profile-bubble.component';
import { OmguiSpinnerComponent } from './omgui-spinner/omgui-spinner.component';
import { OmguiStandardCardModule } from './omgui-standard-card/omgui-standard-card.component';
import { OmguiSvgCameraComponent } from './omgui-svg-camera/omgui-svg-camera.component';

@NgModule({
  declarations: [
    OmguiAppointmentProviderAvatarComponent,
    OmguiProfileBubbleComponent,
    OmguiProviderProfileBubbleComponent,
    OmguiSpinnerComponent,
    OmguiSvgCameraComponent,
  ],
  imports: [
    CommonModule,
    OmguiActionModule,
    OmguiDisplayCardModule,
    OmguiFeatureCardModule,
    OmguiStandardCardModule,
    OmguiCloseXModule,
    OmguiExternalLinkIconModule,
  ],
  exports: [
    OmguiActionComponent,
    OmguiAppointmentProviderAvatarComponent,
    OmguiDisplayCardModule,
    OmguiFeatureCardComponent,
    OmguiProfileBubbleComponent,
    OmguiProviderProfileBubbleComponent,
    OmguiSpinnerComponent,
    OmguiStandardCardModule,
    OmguiSvgCameraComponent,
    OmguiCloseXModule,
    OmguiExternalLinkIconModule,
  ],
})
export class OmguiModule {}
