<om-appointment-booking
  *ngIf="remoteRecommendation"
  [remoteRecommendation]="remoteRecommendation"
  [patientPreferredName]="patientPreferredName"
  [appointmentType]="appointmentType"
  [serviceAreaName]="serviceAreaName"
  [conciergeStepActive$]="conciergeStepActive$"
  [showMultipleSlots]="true"
  [dayInventories]="dayInventories"
  (submit)="onSubmit()"
>
</om-appointment-booking>
