<div class="profile-bubble {{ size }}">
  <div [hidden]="status !== profileBubbleStates.DANGER">
    <span class="decorator">
      <svg [ngSwitch]="size">
        <circle *ngSwitchCase="componentSize.extraSmall" class="danger" cx="21" cy="21" r="19"></circle>
        <circle *ngSwitchCase="componentSize.small" class="danger" cx="26" cy="26" r="24"></circle>
        <circle *ngSwitchCase="componentSize.mediumSmall" class="danger" cx="31" cy="31" r="29"></circle>
        <circle *ngSwitchCase="componentSize.medium" class="danger" cx="51" cy="51" r="49"></circle>
        <circle *ngSwitchCase="componentSize.large" class="danger" cx="68.5" cy="68.5" r="66.5"></circle>
        <circle *ngSwitchCase="componentSize.extraLarge" class="danger" cx="93.5" cy="93.5" r="91.5"></circle>
      </svg>
    </span>
    <img src="/assets/images/icons/error.svg" class="danger-icon" alt="error indicator" />
  </div>
  <div [hidden]="status !== profileBubbleStates.PROCESSING">
    <omgui-spinner class="decorator" [size]="size"></omgui-spinner>
  </div>
  <div [hidden]="status !== profileBubbleStates.REMOTE">
    <omgui-svg-camera class="decorator" [size]="size"></omgui-svg-camera>
  </div>
  <div class="bubble combo-{{ selectedColorCombo }}">
    <div [ngStyle]="profileImageStyle" class="profile-image" *ngIf="source"></div>
    <img class="profile-image hidden" [src]="source" *ngIf="source" (error)="fallbackToInitials($event)" alt="" />
    <div class="initials" [hidden]="source">{{ initials }}</div>
    <div class="shadow"></div>
  </div>
</div>
