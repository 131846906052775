import { Pipe, PipeTransform } from '@angular/core';
import { formatInTimeZone } from 'date-fns-tz';

/**
 * Takes a ISO date string or Date object and formats it according to the formatString
 * See https://date-fns.org/v2.25.0/docs/format for the formatting options
 *
 * If a timezone is provided, parse the date value as UTC time and convert it to the time in the given timezone
 * If a timezone is not provided, the date will be parsed to the users's local timezone
 */
@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(timestamp: string | Date, format: string, timezone?: string): string {
    return formatDate(timestamp, format, timezone);
  }
}

export function formatDate(timestamp: string | Date, format: string, timezone?: string): string {
  if (!timestamp) {
    return '';
  }

  return formatInTimeZone(timestamp, timezone, format);
}
