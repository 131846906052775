import { Injectable } from '@angular/core';

import { ConfigService } from '@app/core/config.service';
import {
  HEALTH_HISTORY_ADULT_SURVEY_ID,
  HEALTH_HISTORY_PEDIATRIC_SURVEY_ID,
} from '@app/home/home-page/home-page.constants';
import { SurveyIds } from '@app/survey/survey-models';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  // Emails
  adminEmail = 'mailto:admin@onemedical.com';

  // Onelife Links
  onelife = {
    homePage: `${this.config.json.myoneServer}/pt/my-one/index`,
    logInUrl: `${this.config.json.myoneServer}/pt/patient/login`,
    resetPassword: `${this.config.json.myoneServer}/pt/auth/forgot_password`,
    medicalRecords: `${this.config.json.myoneServer}/pt/questionnaire/medical-records-release`,
    fsaHsaReimbursement: `${this.config.json.myoneServer}/pt/questionnaire/fsa-hsa-reimbursement`,
    choosePcp: `${this.config.json.myoneServer}/pt/my-one/physicians`,
    providerTeam: `${this.config.json.myoneServer}/pt/my-one/provider_team`,
  };

  // Patient UI Paths
  home = `/`;
  login = '/login';
  membershipRenew = `/membership/renew`;
  membershipRenewConsumer = `/membership/renew/consumer`;
  membershipRenewEnterprise = `/membership/renew/enterprise`;
  membershipRenewOMNow = `/membership/renew/omnow`;
  membershipSettings = `/membership/settings`;
  verifyEmail = '/membership/verify-email';
  registrationEnterprise = `/registration/enterprise`;
  registrationPediatric = `/registration/pediatric`;
  registrationConsumer = `/register`;
  newAppointment = `/appointments/new`;
  healthSurvey = `/survey/${HEALTH_HISTORY_ADULT_SURVEY_ID}`;
  requestRecords = '/account/request-records';
  medicalRecordsRelease = `/survey/${SurveyIds.MEDICAL_RECORDS_RELEASE}`;
  kidsHealthSurvey = `/survey/${HEALTH_HISTORY_PEDIATRIC_SURVEY_ID}`;
  referrals = '/registration/referrals';
  processAfterpay = `${window.location.origin}/register`;
  myProvider = '/account/my-provider';
  selectProvider = '/providers/select-provider';
  onboarding = '/onboarding';

  // External links
  universalPromoTerms = 'https://onemedical.com/100fall#terms';
  afterpay = 'https://portal.afterpay.com/us/login-email';
  tasks = '/tasks';

  constructor(private config: ConfigService) {}

  healthSurveyLink(surveyId: string): string {
    return `/survey/${surveyId}`;
  }

  appointmentConfirmation(id: string): string {
    return `/appointments/${id}/confirmation`;
  }

  employeeIdRegistration(b2bCompanyId: number): string {
    return `${this.registrationEnterprise}/employee_id?b2b_company_id=${b2bCompanyId}`;
  }

  constructWithQueryParams(key, options): string {
    return this[key] + this.constructQueryParams(options);
  }

  constructQueryParams(options): string {
    this.sanitizeParams(options);
    const queryParams: string = new URLSearchParams(options).toString();
    return queryParams ? `?${queryParams}` : '';
  }

  private sanitizeParams(object) {
    Object.keys(object).map(key => {
      if (object[key].length === 0) {
        delete object[key];
      }
    });
  }
}
