import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { UserService } from '@app/core/user.service';

export enum Experiments {
  // Proactive Care Experiments
  APPOINTMENT_BOOKING_EXPERIMENT_NAME = 'PC Show More Appointment Slots',
  APPOINTMENT_BOOKING_EXPERIMENT_VARIANT_NAME = 'Variant: Show more booking slots',
  CONSUMER_ONBOARDING_EXPERIMENT_NAME = 'PC Consumer Onboarding',
  CONSUMER_ONBOARDING_EXPERIMENT_VARIANT_NAME = 'Variant: Show Onboarding',

  // BHX Experiments
  INLINE_SURVEY_EXPERIMENT_NAME = 'Inline Survey',
  INLINE_SURVEY_VARIANT_NAME = 'BHX Homescreen Inline Survey',
}

@Injectable()
export class PatientExperimentService {
  constructor(private apiService: ApiService, private userService: UserService) {}

  getVariantName$(experimentName) {
    return this.userService.getUser().pipe(
      switchMap(user =>
        this.apiService
          .get('/api/v2/patient/patient_experiment', false, {
            patient_id: user.id,
            experiment_name: experimentName,
          })
          .pipe(map((response: any) => response?.experimentVariant)),
      ),
    );
  }

  isVariantEnabled$(experimentName, variantName): Observable<boolean> {
    return this.getVariantName$(experimentName).pipe(
      map((experimentVariant: string) => experimentVariant === variantName),
    );
  }
}
