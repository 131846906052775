import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { DayInventories } from '@app/appointment/provider-inventories';
import { LinksService } from '@app/core/links.service';
import {
  FLOW_HOME_PAGE_NAVIGATION,
  MODULE_CONCIERGE_PAGE,
  MODULE_SCHEDULE_VISIT_PAGE,
} from '@app/core/mixpanel.constants';
import { RecommendedAppointmentInventory_patient_recommendedAppointmentInventory } from '@app/registration/enterprise/__generated__/RecommendedAppointmentInventory';
import { EnterpriseRegistrationAnalyticsService } from '@app/registration/enterprise/enterprise-registration-analytics.service';

import { AppointmentType } from '../../../appointment/appointment-type';
import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-appointment-booking-multiple-slots',
  templateUrl: './appointment-booking-multiple-slots.component.html',
})
export class AppointmentBookingMultipleSlotsComponent extends RegistrationStepDirective {
  @Input() patientPreferredName: string;
  @Input() remoteRecommendation: RecommendedAppointmentInventory_patient_recommendedAppointmentInventory;
  @Input() dayInventories: DayInventories;
  @Input() appointmentType: AppointmentType;
  @Input() serviceAreaName: string;
  @Input() conciergeStepActive$: Observable<boolean>;
  @HostBinding('class.flex-grow-1') setFlexGrow = true;

  constructor(
    private router: Router,
    private links: LinksService,
    private analyticsService: EnterpriseRegistrationAnalyticsService,
  ) {
    super();
  }

  onSubmit() {
    this.conciergeStepActive$.pipe(take(1)).subscribe({
      next: isActive => (isActive ? this.trackMultipleSlotsSeeAllProvidersClicked() : this.redirectToHomePage()),
      error: () => this.redirectToHomePage(),
    });
  }

  trackMultipleSlotsSeeAllProvidersClicked() {
    this.analyticsService.postRegBooking({
      flow: FLOW_HOME_PAGE_NAVIGATION,
      module: MODULE_CONCIERGE_PAGE,
      serviceArea: this.serviceAreaName,
      source: MODULE_SCHEDULE_VISIT_PAGE,
    });

    this.submit.emit();
  }

  redirectToHomePage() {
    this.router.navigate([this.links.home], { queryParams: { source: MODULE_SCHEDULE_VISIT_PAGE } });
  }
}
