import { Injectable } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics.service';
import { FLOW_HOME_PAGE_NAVIGATION } from '@app/core/mixpanel.constants';

@Injectable()
export class NavbarAnalyticsService extends AnalyticsService {
  readonly submodule = 'Navigation Bar';

  trackHealthRecordLink() {
    return super.trackHealthRecordLink({
      submodule: this.submodule,
    });
  }

  trackBookVisitLink() {
    return super.trackBookVisitLink({
      submodule: this.submodule,
    });
  }

  trackHomeLink(querySelector: string) {
    return this.trackWithDefaultProperties('Home Clicked', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: this.submodule,
    });
  }

  trackTasksLink() {
    return this.trackWithDefaultProperties('Tasks Clicked', {
      flow: 'Patient Tasks',
      submodule: this.submodule,
    });
  }

  trackMessagesLink() {
    return this.trackWithDefaultProperties('Messages Clicked', {
      flow: 'Patient Tasks',
      submodule: this.submodule,
    });
  }

  trackRegisterChildLinkSelected() {
    return this.trackWithDefaultProperties('Register Child Link Selected', {
      flow: 'Account Update',
      submodule: this.submodule,
    });
  }

  trackMyProgramSelected({ programName }: { programName: string }) {
    return this.trackWithDefaultProperties('My Program Selected', {
      program: programName,
      submodule: this.submodule,
    });
  }
}
