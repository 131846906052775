import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

import { AppointmentAnalyticsService } from '@app/appointment/appointment-analytics.service';

import { CalendarEventUrl } from '../calendar-event-url';

@Component({
  selector: 'om-add-to-calendar',
  templateUrl: './add-to-calendar.component.html',
  styleUrls: ['./add-to-calendar.component.scss'],
})
export class AddToCalendarComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() location: string;
  @Input() startTime: moment.Moment;
  @Input() endTime: moment.Moment;

  private calendarEventUrl: CalendarEventUrl;

  constructor(private sanitizer: DomSanitizer, private analyticsService: AppointmentAnalyticsService) {}

  ngOnInit() {
    this.calendarEventUrl = new CalendarEventUrl(
      this.title,
      this.description,
      this.location,
      this.startTime,
      this.endTime,
    );
  }

  emitAnalyticsEvent() {
    this.analyticsService.addToCalendarClicked();
  }

  get googleCalendarUrl() {
    return this.calendarEventUrl.googleCalendarUrl();
  }

  get yahooCalendarUrl() {
    return this.calendarEventUrl.yahooCalendarUrl();
  }

  get outlookComCalendarUrl() {
    return this.calendarEventUrl.outlookCalendarUrl();
  }

  get icsFile() {
    return this.sanitizer.bypassSecurityTrustUrl(this.calendarEventUrl.icsFileUrl());
  }
}
