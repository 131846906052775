import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import {
  FLOW_ONBOARDING,
  MODULE_DIRECT_SIGN_UP_SELECTION_PAGE,
  MODULE_SCHEDULE_VISIT_PAGE,
} from '@app/core/mixpanel.constants';
import { EnterpriseRegistrationAnalyticsService } from '@app/registration/enterprise/enterprise-registration-analytics.service';

import { EnterpriseRegistration } from '../enterprise-registration';
import { RegistrationStep } from '../registration-step';
import { StepName } from '../registration-step-name';
import { DirectSignupStepComponent } from './direct-signup-step.component';

@Injectable({
  providedIn: 'root',
})
export class DirectSignupConfig extends RegistrationStep implements OnDestroy {
  private selectedStep$ = new ReplaySubject<StepName>(1);
  private destroy$ = new Subject<void>();

  readonly MODULE = MODULE_DIRECT_SIGN_UP_SELECTION_PAGE;
  readonly component = DirectSignupStepComponent;
  readonly progress = 90;
  readonly form: FormGroup;

  constructor(private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService) {
    super();
  }

  initComponent(component: DirectSignupStepComponent, state: EnterpriseRegistration): void {
    component.nextStepSelected.asObservable().pipe(takeUntil(this.destroy$)).subscribe(this.selectedStep$);
    component.module = this.MODULE;
    component.isWhitelisted = state.isWhitelisted;
  }

  submit(state: EnterpriseRegistration): Observable<boolean> {
    return this.selectedStep$.pipe(
      take(1),
      tap(step => {
        this.trackBookingMultipleSlotsFlow(step, state);
        state.setCurrentStep(step);
      }),
      map(() => true),
    );
  }

  private trackBookingMultipleSlotsFlow(stepName: string, state: EnterpriseRegistration) {
    if (stepName === StepName.appointmentBookingMultipleSlots) {
      this.enterpriseRegistrationAnalyticsService.postRegBooking({
        flow: FLOW_ONBOARDING,
        module: MODULE_SCHEDULE_VISIT_PAGE,
        serviceArea: state._patient.serviceArea.name,
        source: MODULE_DIRECT_SIGN_UP_SELECTION_PAGE,
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
