import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import first from 'lodash-es/first';
import get from 'lodash-es/get';
import groupBy from 'lodash-es/groupBy';
import { Observable, of as observableOf } from 'rxjs';

import { AppointmentType } from '@app/appointment/appointment-type';
import { AppointmentInventory } from '@app/appointment/provider-inventories';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LinksService } from '@app/core/links.service';
import { MODULE_SCHEDULE_VISIT_PAGE } from '@app/core/mixpanel.constants';
import { AppointmentBookingStepComponent } from '@app/registration/enterprise/appointment-booking/appointment-booking-step.component';
import { EnterpriseRegistration } from '@app/registration/enterprise/enterprise-registration';
import { RegistrationStep } from '@app/registration/enterprise/registration-step';
import { StepName } from '@app/registration/enterprise/registration-step-name';

import { RecommendedAppointmentInventory } from '../__generated__/RecommendedAppointmentInventory';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RecommendedAppointmentInventoryGraphQL } from '../recommended-appointment-inventory-graphql.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentBookingConfig extends RegistrationStep {
  readonly MODULE = MODULE_SCHEDULE_VISIT_PAGE;
  readonly progress = 100;
  readonly component = AppointmentBookingStepComponent;
  form: FormGroup;

  constructor(
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private recommendedInventoryGraphQL: RecommendedAppointmentInventoryGraphQL,
    private router: Router,
    private featureFlagSelectors: FeatureFlagSelectors,
    private links: LinksService,
  ) {
    super();
  }

  initComponent(component: AppointmentBookingStepComponent, state: EnterpriseRegistration): void {
    this.recommendedInventoryGraphQL
      .watch({
        recommendRemoteResults: true,
        serviceAreaId: `${state.serviceArea.id}`,
      })
      .valueChanges.subscribe((result: ApolloQueryResult<RecommendedAppointmentInventory>) => {
        if (get(result, 'data.patient.recommendedAppointmentInventory')) {
          component.remoteRecommendation = result.data.patient.recommendedAppointmentInventory;
          const appointmentInventories = component.remoteRecommendation.appointmentInventories.map(inv =>
            AppointmentInventory.fromGraphQL(inv),
          );

          component.appointmentType = AppointmentType.fromGraphQL(component.remoteRecommendation.appointmentType);
          component.dayInventories = groupBy(appointmentInventories, 'date');
          component.patientPreferredName = state.patient.preferredName || state.patient.firstName;
          component.serviceAreaName = state.serviceArea.name;

          const firstDateAvailable = first(Object.keys(component.dayInventories));
          const inventoryLength = component.dayInventories[firstDateAvailable].length;
          this.trackPageViewed(state, this.enterpriseRegistrationAnalyticsService, inventoryLength);
        } else {
          this.router.navigateByUrl(this.links.home);
        }
      });

    component.conciergeStepActive$ = this.featureFlagSelectors.getFeatureFlag(
      FeatureFlags.PROACTIVE_CARE_ENABLE_CONCIERGE,
      false,
    );
  }

  submit(state: EnterpriseRegistration): Observable<boolean> {
    state.setCurrentStep(StepName.concierge);
    return observableOf(true);
  }

  private trackPageViewed(
    state: EnterpriseRegistration,
    analytics: EnterpriseRegistrationAnalyticsService,
    inventoryCount: number,
  ) {
    analytics.trackScheduleVisitPageViewed({
      module: this.MODULE,
      source: state.getPreviousStep()?.MODULE,
      isWhitelist: state.isWhitelisted,
      serviceArea: state.serviceArea.id,
      inventoryCount: inventoryCount,
    });
  }
}
