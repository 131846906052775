import { CommonModule } from '@angular/common';
import { Component, ContentChild, Directive, EventEmitter, Input, NgModule, Output, TemplateRef } from '@angular/core';
import { RouterModule } from '@angular/router';

@Directive({
  selector: '[omgui-standard-card-icon]',
})
export class OmguiStandardCardIconDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[omgui-standard-card-footer]',
})
export class OmguiStandardCardFooterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'omgui-standard-card',
  templateUrl: './omgui-standard-card.component.html',
  styleUrls: ['./omgui-standard-card.component.scss'],
})
export class OmguiStandardCardComponent {
  @ContentChild(OmguiStandardCardIconDirective) icon: OmguiStandardCardIconDirective;
  @ContentChild(OmguiStandardCardFooterDirective) footer: OmguiStandardCardFooterDirective;

  /** The title or main text */
  @Input() label: string;

  /** Subtitle or a short description */
  @Input() sublabel: string;

  /** Navigation link */
  @Input() link: string;

  /** Emits an event on click */
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  onClick(event: Event): void {
    event.preventDefault();
    this.clicked.emit();
  }
}

@NgModule({
  declarations: [OmguiStandardCardComponent, OmguiStandardCardIconDirective, OmguiStandardCardFooterDirective],
  imports: [CommonModule, RouterModule],
  exports: [OmguiStandardCardComponent, OmguiStandardCardIconDirective, OmguiStandardCardFooterDirective],
})
export class OmguiStandardCardModule {}
