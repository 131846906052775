import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import cloneDeep from 'lodash-es/cloneDeep';
import merge from 'lodash-es/merge';
import { Dict, Mixpanel } from 'mixpanel-browser';
import queryString from 'query-string';
import { asyncScheduler, bindCallback, Observable } from 'rxjs';

import { State } from '@app/app.reducer';
import { updateAnalyticsProperties } from '@app/core/analytics.actions';
import { AnalyticsState, getAnalyticsState } from '@app/core/analytics.reducer';
import { TrackMetricProps } from '@app/core/feature-flags/interfaces';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { Membership } from '@app/core/membership';
import { MixpanelService } from '@app/core/mixpanel.service';
import { EMAIL_VERIFICATION_OUTREACH } from '@app/home/home-page/home-page.constants';
import { DocumentItem } from '@app/shared/document-item';
import { VisitSummary } from '@app/shared/visit-summary/visit-summary';

import { CommentItem } from '../message/comment-item';
import { MessageItem } from '../message/message-item';
import { UploadableFile } from '../message/uploadable-file';
import { Task } from '../shared/task';
import { BasicFollowUpOrderContent } from '../shared/task-content.types';
import { User } from '../shared/user';
import {
  EMAIL_VERIFICATION_OUTREACH_EMAIL,
  MODULE_ALL_TASKS_PAGE,
  MODULE_HOME_PAGE,
  MODULE_PCP_OVERVIEW,
  MODULE_PCP_SELECTED_MODAL_PAGE,
  MODULE_PCP_SELECTION_PAGE,
  MODULE_SERVICE_AREA_PAGE,
  MP_EVENT_PAGE_VIEWED,
  MP_USER_PROFILE_MFA_PROPERTY_NAME,
} from './mixpanel.constants';

enum EVENT {
  PAGE_VIEWED = 'Page Viewed',
  UNIVERSAL_LINK_SELECTED = 'Universal Link Selected',
}

enum FLOW {
  ACCESSING_PHR = 'Accessing PHR',
  CART_ABANDONMENT_EMAIL = 'Cart Abandonment Email',
  PATIENT_TASKS = 'Patient Tasks',
  SURVEYS = 'Surveys',
}

export const ANALYTICS_TIMESTAMP_FORMAT = 'yyyy-MM-dd HH:mm:ss';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  static readonly connectedAccountsCommonProperties = {
    flow: 'Account Update',
    module: 'Apps and Devices Page',
  };

  private readonly mixpanel: Mixpanel;
  private currentUserId: number;
  protected module: string;
  protected defaultProperties: AnalyticsState;
  protected isWhitelist: boolean;

  previousRoute: string;

  /**
   * @deprecated use trackWithDefaultProperties instead
   */
  protected track(eventName: string, properties?: Dict): Observable<any> {
    const track = bindCallback(this.mixpanel.track, undefined, asyncScheduler).call(
      this.mixpanel,
      eventName,
      properties,
    );

    track.subscribe();
    return track;
  }

  protected trackWithDefaultProperties(eventName: string, properties?: Dict): Observable<any> {
    const props = { ...this.defaultProperties, ...properties };

    const track = bindCallback(this.mixpanel.track, undefined, asyncScheduler).call(this.mixpanel, eventName, props);
    track.subscribe();

    return track;
  }

  /* Avoid Circular Dependencies: Avoid injecting any patient-ui services here. */
  constructor(
    private mixpanelService: MixpanelService,
    private store: Store<State>,
    private launchDarklyService: LaunchDarklyService,
    private router?: Router,
  ) {
    this.mixpanel = mixpanelService.instance;
    this.launchDarklyService = launchDarklyService;
    this.store.pipe(select(getAnalyticsState)).subscribe({
      next: (state: AnalyticsState) => (this.defaultProperties = state),
    });

    this.trackCampaigns(document.location);

    if (this.router) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.previousRoute = this.router.url;
        }
      });
    }
  }

  trackHealthHistoryViewed(source: string, survey_id: string) {
    const properties = {
      flow: 'Survey',
      source: source,
      module: 'Survey Start Page',
      survey_id: survey_id,
    };

    this.trackWithDefaultProperties(EVENT.PAGE_VIEWED, properties);
  }

  setModule(module: string) {
    this.module = module;
  }

  trackWithLaunchDarkly(props: TrackMetricProps) {
    this.launchDarklyService.track(props);
  }

  trackFeatureFlags(featureFlags: string[]) {
    this.trackWithDefaultProperties('Feature Flags Synced', { feature_flags_on: featureFlags });
  }

  visitSummariesPageViewed(visitSummaries: VisitSummary[]) {
    const properties = {
      flow: FLOW.PATIENT_TASKS,
      module: 'Visit Summaries Page',
    };

    this.trackWithDefaultProperties(EVENT.PAGE_VIEWED, properties);
  }

  visitSummaryPageViewed(visitSummary: VisitSummary, source?: string) {
    const properties = {
      flow: FLOW.PATIENT_TASKS,
      module: 'Visit Summary Details Page',
      source: source || MODULE_ALL_TASKS_PAGE,
      after_visit_summary_id: visitSummary.id,
    };

    this.trackWithDefaultProperties(EVENT.PAGE_VIEWED, properties);
  }

  // getting to the task list
  patientTaskOverviewViewed(taskCount: number, source?: string) {
    const properties = {
      module: MODULE_ALL_TASKS_PAGE,
      flow: FLOW.PATIENT_TASKS,
      'Patient Task Count': taskCount,
      source: source || 'Homescreen Page',
    };
    this.trackWithDefaultProperties(EVENT.PAGE_VIEWED, properties);
  }

  // from task list to current task [Visit Summary Details Page | Email | All Tasks Page]
  patientTaskViewed(task: Task, source?: string) {
    const properties = {
      'Task Id': task.id,
      'Task Type': task.type,
      'Task State': task.state,
      'Task Scheduled At': task.scheduledAt,
      module: 'Task Page',
      flow: 'Patient Tasks',
      source: source,
    };

    return this.trackWithDefaultProperties(EVENT.PAGE_VIEWED, properties);
  }

  // historical patient tasks from task list --> task [Visit Summary Details Page | Email | Historical Patient Tasks Page ]
  historicalTaskViewed(task: Task, source?: string) {
    const properties = {
      flow: FLOW.PATIENT_TASKS,
      module: ' Historical Patient Task Page',
      'Task Type': task.type,
      'Task State': task.state,
      'Task Id': task.id,
      source: source,
    };

    return this.trackWithDefaultProperties(EVENT.PAGE_VIEWED, properties);
  }

  patientTaskDeclineTapped(task: Task) {
    return this.track('Patient Task Decline Tapped', this.taskCommonProperties(task));
  }

  patientTaskDeclined(task: Task) {
    return this.track('Patient Task Declined', this.taskCommonProperties(task));
  }

  patientTaskAlreadyDoneTapped(task: Task, property?) {
    const taskProperties = this.taskCommonProperties(task);
    if (task.type === 'consult_order') {
      taskProperties['already_done_type'] = property.specialist;
    }
    return this.track('Patient Task Already Done Tapped', taskProperties);
  }

  patientTaskAlreadyDone(task: Task, property?) {
    const taskProperties = this.taskCommonProperties(task);
    if (task.type === 'consult_order') {
      taskProperties['already_done_type'] = property.specialist;
      this.consultOrderSpecialistEventSelector(task, property);
    }
    if (task.type === 'basic_follow_up_order') {
      this.patientTaskSubmitted(task);
    } else {
      return this.track('Patient Task Already Done Confirmed', taskProperties);
    }
  }

  consultOrderSpecialistEventSelector(task: Task, property) {
    if (property.specialist === 'Elsewhere') {
      const specialistModule = property.specialistInfo
        ? 'Specialist Information Submitted'
        : 'Specialist Information Unknown Submitted';
      this.track(specialistModule, this.taskCommonProperties(task));
    }
  }

  patientTaskSubmitted(task: Task) {
    const taskProperties = this.taskCommonProperties;
    taskProperties['Task Feedback'] = (<BasicFollowUpOrderContent>task.content).feedback;
    return this.track('Patient Task Submitted', taskProperties);
  }

  referralPDFViewed(task: Task) {
    this.trackWithDefaultProperties('View Referral PDF Clicked', this.taskCommonProperties(task));
  }

  bloodPressureEntryViewed() {
    return this.track('Blood Pressure Entry Viewed');
  }

  bloodPressureSubmitted() {
    return this.track('Blood Pressure Submitted');
  }

  attachmentCreated(attachment: UploadableFile) {
    return this.track('Timeline Attachment Created', {
      'Content Type': attachment.type,
      'Content Length': attachment.size,
    });
  }

  attachmentCreationFailed(attachment: UploadableFile) {
    return this.track('Timeline Attachment Creation Failed', {
      'Content Type': attachment.type,
      'Content Length': attachment.size,
    });
  }

  attachmentDeleted(attachment: UploadableFile) {
    return this.track('Timeline Attachment Deleted', {
      'Content Type': attachment.type,
      'Content Length': attachment.size,
    });
  }

  attachmentViewed(document: DocumentItem) {
    return this.track('Timeline Attachment Deleted', {
      'Content Type': document.contentType,
      'Content Length': document.contentLength,
    });
  }

  attachmentDownloaded(document: DocumentItem) {
    return this.track('Timeline Attachment Deleted', {
      'Content Type': document.contentType,
      'Content Length': document.contentLength,
    });
  }

  messagesPageLoad(source?: string): void {
    const properties = {
      ...this.messagingCommonProperties(),
      Referrer: document.referrer,
      source: source,
    };

    this.trackWithDefaultProperties('Timeline Post Overview Viewed', properties);
  }

  messageDetailPageLoad(post: MessageItem) {
    return this.trackWithDefaultProperties('Timeline Post Details Viewed', this.messagingCommonProperties({ post }));
  }

  postStarted(post: MessageItem) {
    return this.trackWithDefaultProperties('Timeline Post Started', this.messagingCommonProperties({ post }));
  }

  commentStarted(comment: CommentItem) {
    return this.trackWithDefaultProperties('Timeline Comment Started', this.messagingCommonProperties({ comment }));
  }

  draftPostLoaded(post: MessageItem) {
    return this.trackWithDefaultProperties('Timeline Draft Post Loaded', this.messagingCommonProperties({ post }));
  }

  draftCommentLoaded(comment: CommentItem) {
    return this.trackWithDefaultProperties(
      'Timeline Draft Comment Loaded',
      this.messagingCommonProperties({ comment }),
    );
  }

  postCreated(post: MessageItem) {
    this.mixpanel.people.increment('Timeline Post Created');
    this.mixpanel.people.set({ 'Last Timeline Post Created': new Date(post.timestamp) });

    return this.trackWithDefaultProperties('Timeline Post Created', {
      ...this.messagingCommonProperties({ post }),
      'Text Length': post.content.length,
      'Attachment Count': post.documents.length,
    });
  }

  postCreationFailed(post: MessageItem, error: HttpErrorResponse) {
    return this.trackWithDefaultProperties('Timeline Post Creation Failed', {
      ...this.messagingCommonProperties({ post }),
      'Text Length': post.content.length,
      'Attachment Count': post.documents.length,
      'Error Code': error.status,
      'Error Description': error.message,
      'Error Status Text': error.statusText,
      'Error Type': error.name,
    });
  }

  commentCreated(postId: number, comment: CommentItem) {
    this.mixpanel.people.increment('Timeline Comment Created');
    this.mixpanel.people.set({ 'Last Timeline Comment Created': new Date(comment.timestamp) });

    return this.trackWithDefaultProperties('Timeline Comment Created', {
      ...this.messagingCommonProperties({ comment }),
      'Text Length': comment.content.length,
      'Attachment Count': comment.documents.length,
    });
  }

  commentCreationFailed(postId: number, comment: CommentItem, error: HttpErrorResponse) {
    return this.trackWithDefaultProperties('Timeline Comment Creation Failed', {
      ...this.messagingCommonProperties({ comment }),
      'Text Length': comment.content.length,
      'Attachment Count': comment.documents.length,
      'Error Code': error.status,
      'Error Description': error.message,
      'Error Status Text': error.statusText,
      'Error Type': error.name,
    });
  }

  surveyLoaded(surveyId: string, source?: string) {
    return this.trackWithDefaultProperties('Survey Loaded', {
      'Survey Id': surveyId,
      flow: FLOW.SURVEYS,
      ...(source != null && { source }),
    });
  }

  surveyNextQuestion(surveyId: string, questionId: string) {
    return this.track('Load Next Question', {
      'Survey Id': surveyId,
      'Question Id': questionId,
    });
  }

  surveySubmitted(surveyId: string) {
    return this.track('Submit Survey', {
      'Survey Id': surveyId,
    });
  }

  appInstallBannerDismissed() {
    return this.track('App Install Banner Dismissed', {});
  }

  appInstallBannerAccepted() {
    return this.track('App Install Banner Accepted', {});
  }

  appStoreLinkClicked(location: string) {
    return this.track('App Store Link Clicked', {
      Location: location,
    });
  }

  trackHealthRecordLink(properties: { [key: string]: any } = {}) {
    return this.trackWithDefaultProperties('Health Record Clicked', {
      flow: 'Accessing PHR',
      ...properties,
    });
  }

  trackBookVisitLink(properties: { [key: string]: any } = {}) {
    return this.trackWithDefaultProperties('Book Visit Clicked', {
      flow: 'Appointment Booking',
      ...properties,
    });
  }

  trackDirectSignupStarted() {
    return this.trackWithDefaultProperties('Direct Sign Up Started', {
      flow: 'Direct Sign Up',
      module: MODULE_HOME_PAGE,
      submodule: 'Header Banner',
      is_whitelist: this.isWhitelist,
      is_logged_in: true,
    });
  }

  trackReferralModalOpen() {
    this.track('Friends and Family Referral Started', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
      submodule: 'MyOne Header Banner',
    });
  }

  trackReferralBannerShown(properties) {
    this.track('Banner Displayed', {
      ...this.defaultProperties,
      ...properties,
      flow: 'Referral',
      module: this.module,
      submodule: 'MyOne Header Banner',
    });
  }

  trackReferralSubmissionError() {
    this.track('Submit Error Encountered', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
    });
  }

  trackReferralLinkCopied() {
    this.track('Friends and Family Referral Link Copied', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
    });
  }

  trackInviteMoreFriends() {
    this.track('Invite More Friends and Family Clicked', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
    });
  }

  trackReferralModalClosed() {
    this.track('Friends and Family Referral Modal Closed', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
    });
  }

  trackReferralSubmitted(email: string) {
    this.track('Friends and Family Referral Submitted', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
      emails: [email],
      numEmails: 1,
    });
  }

  trackLoginPageView(queryParams: Params) {
    let source = '';
    if (this.isGoingToHomeFromEmailVerificationOutreach(queryParams)) {
      source = EMAIL_VERIFICATION_OUTREACH_EMAIL;
    } else if (['serviceAreaPageBanner', 'serviceAreaPageModal'].includes(queryParams.source)) {
      source = MODULE_SERVICE_AREA_PAGE;
    }

    const props = {
      source: source,
      flow: 'Login',
      module: 'Login Page',
      module_variant: queryParams.familyPromo === 'true' ? 'OM Kids Special 2021' : '',
    };

    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, props);
  }

  trackDirectLinksToLoggedInResources(attemptedPath: string) {
    if (attemptedPath.match('/membership/settings\\?gift_code=')) {
      this.trackGiftCodeRedemptionLoginPageView();
    }
  }

  pedsRegLinkClicked(properties) {
    return this.track('Pediatric Link Clicked', properties);
  }

  trackWithErrors(eventName: string, properties) {
    this.ensureRequiredPropertiesIncluded(properties);
    return this.track(eventName, properties);
  }

  identifyNewUser(user: User) {
    this.mixpanel.alias(`${user.id}`);
    this.mixpanel.identify(`${user.id}`);
  }

  trackHealthInformationReleaseAuthPageView() {
    this.track(MP_EVENT_PAGE_VIEWED, {
      ...this.defaultProperties,
      flow: 'Survey',
      is_whitelist: this.isWhitelist,
      module: 'COVID-19 Health Information Release Authorization Page',
    });
  }

  choosePharmacyPageViewed() {
    this.trackWithDefaultProperties(EVENT.PAGE_VIEWED, this.preferredPharmacyCommonProperties());
  }

  preferredPharmacySelected() {
    this.trackWithDefaultProperties('Preferred Pharmacy Selected', this.preferredPharmacyCommonProperties());
  }

  myProviderSidebarItemClicked() {
    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Account Update',
      module: 'PCP Overview Page',
    });
  }

  chooseProviderClicked(source, props?) {
    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'PCP Selection',
      module: props?.module ?? MODULE_PCP_SELECTION_PAGE,
      source: source,
    });
  }

  myPCPSelected() {
    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'PCP Selection',
      module: MODULE_PCP_SELECTED_MODAL_PAGE,
      source: MODULE_PCP_OVERVIEW,
    });
    this.trackWithLaunchDarkly({ key: 'PCP Selected' });
  }

  cartAbandonmentLinkClicked(user: User) {
    this.trackWithDefaultProperties(EVENT.UNIVERSAL_LINK_SELECTED, { 'Universal Link': 'Cart Abandonment Email' });
  }

  resetMixpanelId() {
    this.mixpanel.reset();
  }

  identifyAndUpdateUser(user: User) {
    this.store.dispatch(
      updateAnalyticsProperties({
        updates: {
          service_area: user.serviceArea?.name,
        },
      }),
    );

    this.isWhitelist = user.whitelistedEmployee;

    if (this.currentUserId !== user.id) {
      this.mixpanel.identify(`${user.id}`);
      this.mixpanel.people.set({ Id: user.id });
      this.currentUserId = user.id;
    }
  }

  updateMFAProperties = ({ mfaEnabled }: { mfaEnabled: boolean }) => {
    this.mixpanel.people.set({ [MP_USER_PROFILE_MFA_PROPERTY_NAME]: mfaEnabled });
  };

  updateMembershipProperties(membership: Membership) {
    if (!membership.omMembershipType) {
      throw new Error(
        `Unset membership type. Expected om_membership_type from membership but ${membership.omMembershipType} was found`,
      );
    }

    this.store.dispatch(
      updateAnalyticsProperties({
        updates: {
          membership_status: membership.status,
          om_membership_type: membership.omMembershipType,
          ...(membership?.b2bCompany && { b2b_company_name: membership?.b2bCompany?.displayName }),
        },
      }),
    );
  }

  private isGoingToHomeFromEmailVerificationOutreach(queryParams: Params): boolean {
    return queryParams.returnUrl === `/?source=${EMAIL_VERIFICATION_OUTREACH}`;
  }

  private trackGiftCodeRedemptionLoginPageView() {
    this.track(MP_EVENT_PAGE_VIEWED, {
      ...this.defaultProperties,
      flow: 'Post Gift Registration',
      module: 'Gift Login Page',
    });
  }

  private ensureRequiredPropertiesIncluded(properties) {
    if (!properties.flow) {
      throw new Error('"flow" is a required property');
    }
    if (!properties.module) {
      throw new Error('"module" is a required property');
    }
  }

  private taskCommonProperties(task) {
    return {
      patient_task_id: task.id,
      'Task Id': task.id,
      patient_task_type: task.type,
      'Task Type': task.type,
      'Task State': task.state,
      'Task Scheduled At': task.scheduledAt,
      'Task Provider Id': task.provider.id,
      module: this.module,
      flow: 'Patient Tasks',
    };
  }

  private messagingCommonProperties({ post, comment }: { post?: MessageItem; comment?: CommentItem } = {}) {
    const properties: { [key: string]: string | number } = {
      flow: 'Messages Overview',
      module: this.module,
    };

    if (post) {
      properties.recipient = post.recipient;
      properties['Post Id'] = post.id;
    }

    if (comment) {
      properties.recipient = comment.originalPost.recipient;
      properties['Post Id'] = comment.originalPost.id;
      properties['Comment Id'] = comment.id;
    }

    return properties;
  }

  private trackCampaigns(location) {
    const keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
    const lastValues = {},
      firstValues = {};
    const params = queryString.parse(location.search, { decode: true });
    for (const keyword of Object.keys(keywords)) {
      const value = params[keyword];
      if (value) {
        lastValues[keyword + ' [last touch]'] = value;
        firstValues[keyword + ' [first touch]'] = value;
      }
    }
    this.mixpanel.people.set_once(firstValues);
    this.mixpanel.people.set(lastValues);
    this.mixpanel.register(lastValues);
  }

  private preferredPharmacyCommonProperties() {
    return {
      flow: 'Preferred Pharmacy',
      module: 'Preferred Pharmacy Page',
    };
  }

  trackConnectedAccountsPageViewed(isFitbitConnected: boolean) {
    let properties = cloneDeep(AnalyticsService.connectedAccountsCommonProperties);
    if (isFitbitConnected) {
      properties = merge(properties, { module_variant: 'Fitbit Connected State' });
    }
    this.trackWithDefaultProperties('Page Viewed', properties);
  }

  trackConnectYourFitbitClicked() {
    return this.trackWithDefaultProperties(
      'Connect Your Fitbit Clicked',
      AnalyticsService.connectedAccountsCommonProperties,
    );
  }

  trackFitbitSuccessfullyConnected() {
    this.trackWithDefaultProperties(
      'Fitbit Successfully Connected',
      AnalyticsService.connectedAccountsCommonProperties,
    );
  }

  trackRequestRecordsPageViewed(user: User, membership: Membership) {
    this.trackWithDefaultProperties('Page Viewed', {
      flow: 'Account Update',
      module: 'Request Records Page',
      service_area: user.serviceArea.name,
      om_membership_type: membership.omMembershipType,
      membership_status: membership.status,
    });
  }

  trackDownloadMedicalRecordsClicked(user: User, membership: Membership) {
    this.trackWithDefaultProperties('Download Medical Record Clicked', {
      flow: 'Account Update',
      module: 'Request Records Page',
      service_area: user.serviceArea.name,
      om_membership_type: membership.omMembershipType,
      membership_status: membership.status,
    });
  }
}
