import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LinksService } from '@app/core/links.service';
import { MODULE_CONCIERGE_PAGE } from '@app/core/mixpanel.constants';
import { WindowService } from '@app/core/window.service';
import { conciergeDefaultOptions, OptionValue } from '@app/onboarding/concierge/concierge.utils';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'concierge-step',
  templateUrl: './concierge-step.component.html',
  styleUrls: ['./concierge-step.component.scss'],
})
export class ConciergeStepComponent extends RegistrationStepDirective implements OnInit {
  @Input() healthIntakeSurveyId: string;

  readonly optionsControl = new FormControl();
  options = conciergeDefaultOptions;

  constructor(
    private router: Router,
    private links: LinksService,
    private windowService: WindowService,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {
    super();
  }

  ngOnInit(): void {
    this.optionsControl.valueChanges.pipe(take(1)).subscribe({
      next: (option: OptionValue) => {
        if (option === OptionValue.chooseProvider) {
          this.router.navigate([this.links.selectProvider], { queryParams: { source: MODULE_CONCIERGE_PAGE } });
        } else if (option === OptionValue.healthHistory) {
          this.router.navigate([this.links.healthSurveyLink(this.healthIntakeSurveyId)], {
            queryParams: { source: MODULE_CONCIERGE_PAGE },
          });
        } else if (option === OptionValue.requestMeds) {
          this.router.navigate([this.links.home], {
            queryParams: { action: 'show-treat-me-now', source: MODULE_CONCIERGE_PAGE },
          });
        }
      },
    });
  }

  skip() {
    this.router.navigate([this.links.home], { queryParams: { source: MODULE_CONCIERGE_PAGE } });
  }
}
